export default {
  visState: {
    filters: [
      {
        dataId: ["humi_map"],
        id: "qzmjq1bcr",
        name: ["timestamp"],
        type: "timeRange",
        value: [1631172790000, 1631172997601],
        enlarged: true,
        plotType: "histogram",
        animationWindow: "incremental",
        yAxis: null,
        speed: 2,
      },
      {
        dataId: ["humi_map"],
        id: "62tvamkk5",
        name: ["value"],
        type: "range",
        value: [35.6, 60.68],
        enlarged: false,
        plotType: "histogram",
        animationWindow: "free",
        yAxis: null,
        speed: 1,
      },
    ],
    layers: [
      {
        id: "ezl4x7",
        type: "point",
        config: {
          dataId: "humi_map",
          label: "Point",
          color: [30, 150, 190],
          highlightColor: [252, 242, 26, 255],
          columns: { lat: "lat", lng: "lng", altitude: null },
          isVisible: true,
          visConfig: {
            radius: 11.7,
            fixedRadius: false,
            opacity: 0.8,
            outline: true,
            thickness: 1,
            strokeColor: null,
            colorRange: {
              name: "Uber Viz Sequential 4",
              type: "sequential",
              category: "Uber",
              colors: [
                "#E6FAFA",
                "#C1E5E6",
                "#9DD0D4",
                "#75BBC1",
                "#4BA7AF",
                "#00939C",
              ],
            },
            strokeColorRange: {
              name: "Global Warming",
              type: "sequential",
              category: "Uber",
              colors: [
                "#5A1846",
                "#900C3F",
                "#C70039",
                "#E3611C",
                "#F1920E",
                "#FFC300",
              ],
            },
            radiusRange: [0, 50],
            filled: true,
          },
          hidden: false,
          textLabel: [
            {
              field: null,
              color: [255, 255, 255],
              size: 18,
              offset: [0, 0],
              anchor: "start",
              alignment: "center",
            },
          ],
        },
        visualChannels: {
          colorField: { name: "value", type: "real" },
          colorScale: "quantile",
          strokeColorField: null,
          strokeColorScale: "quantile",
          sizeField: null,
          sizeScale: "linear",
        },
      },
    ],
    interactionConfig: {
      tooltip: {
        fieldsToShow: {
          humi_map: [
            { name: "name", format: null },
            { name: "timestamp", format: null },
            { name: "value", format: null },
          ],
        },
        compareMode: false,
        compareType: "absolute",
        enabled: true,
      },
      brush: { size: 0.5, enabled: false },
      geocoder: { enabled: false },
      coordinate: { enabled: false },
    },
    layerBlending: "normal",
    splitMaps: [],
    animationConfig: { currentTime: null, speed: 1 },
  },
  mapState: {
    bearing: 0,
    dragRotate: false,
    latitude: 51.95843274867304,
    longitude: 7.619524789553678,
    pitch: 0,
    zoom: 12.995770213984256,
    isSplit: false,
  },
  mapStyle: {
    styleType: "satellite",
    topLayerGroups: {},
    visibleLayerGroups: {},
    threeDBuildingColor: [
      3.7245996603793508, 6.518049405663864, 13.036098811327728,
    ],
    mapStyles: {},
  },
};
